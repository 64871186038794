<template>
	<div class="wrapper">
		<div
			style="margin: 100px auto; background-color: #fff; width:650px; height: 750px; padding: 20px; border-radius: 20px">
			<div style="margin: 20px 0; text-align: center; font-size: 24px"><b>欢迎注册招采平台</b></div>
			<el-form :model="user" :rules="rules" ref="userForm">
				<el-form-item prop="gscode" label="统一社会信用代码">
					<el-input placeholder="请输入账号" size="medium" prefix-icon="el-icon-user" v-model="user.tongyicode" />
					</el-input>
				</el-form-item>
				<el-form-item prop="gongsiname" label="公司名称">
					<el-input placeholder="请输入公司名称" size="medium" prefix-icon="el-icon-user" v-model="user.gysname">
					</el-input>
				</el-form-item>
				<el-form-item prop="username" label="管理员姓名">
					<el-input placeholder="请输入管理员姓名" size="medium" prefix-icon="el-icon-user" v-model="user.username">
					</el-input>
				</el-form-item>
				<el-form-item prop="phone" label="管理员手机号">
					<el-input placeholder="请输入管理员手机号" size="medium" prefix-icon="el-icon-user"
						v-model="user.farenphone">
					</el-input>
				</el-form-item>
				<el-form-item prop="code" label="验证码">
					<el-input placeholder="请输入验证码" size="medium" prefix-icon="el-icon-user" v-model="user.code">
					</el-input>
					<el-button type="success" style="margin-top: 0.1875rem;" size="small" :disabled="isDisabl" @click="sendSms">{{param}}</el-button>
				</el-form-item>
				<el-form-item prop="password" label="密码">
					<el-input placeholder="请输入密码" size="medium" prefix-icon="el-icon-lock" show-password
						v-model="user.password"></el-input>
				</el-form-item>
				<el-form-item prop="confirmPassword" label="确认密码">
					<el-input placeholder="请确认密码" size="medium" prefix-icon="el-icon-lock" show-password
						v-model="user.confirmPassword"></el-input>
				</el-form-item>
				<el-form-item style="margin: 5px 0; text-align: center">
					<el-button type="primary" size="small" autocomplete="off"  @click="login">注册</el-button>
					<el-button type="warning" size="small" autocomplete="off" @click="$router.push('/login')">返回登录
					</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Login",
		data() {
			return {
				param: '发送短信',
				isDisabl:false,
				user: {},
				rules: {
					tongyicode: [{
							required: true,
							message: '请输入统一社会信用代码',
							trigger: 'blur'
						},
						{
							min: 18,
							max: 18,
							message: '统一社会信用代码长度为18个字符',
							trigger: 'blur'
						}
					],
					username: [{
							required: true,
							message: '请输入用户名',
							trigger: 'blur'
						},
						{
							min: 3,
							max: 10,
							message: '长度在 3 到 5 个字符',
							trigger: 'blur'
						}
					],
					farenphone: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							min: 11,
							max: 11,
							message: '长度在 11个字符',
							trigger: 'blur'
						}
					],
					password: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 20,
							message: '长度在 1 到 20 个字符',
							trigger: 'blur'
						}
					],
					confirmPassword: [{
							required: true,
							message: '请再次输入密码',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 20,
							message: '长度在 1 到 20 个字符',
							trigger: 'blur'
						}
					],
				}
			}
		},
		methods: {
			login() {
				this.$refs['userForm'].validate((valid) => {
					if (valid) { // 表单校验合法
						if (this.user.password !== this.user.confirmPassword) {
							this.$message.error("两次输入的密码不一致!")
							return false
						}
						if(this.user.code!==JSON.parse(localStorage.getItem ("smsCode"))){
							this.$message.error("验证码错误!")
							return false
						}
						this.request.post("/user/register", this.user).then(res => {
							if (res.code === '200') {
								this.$message.success("注册成功,基本资料已自动获取,请前往资料中心完善资料并上传相关文件!")
							} else {
								this.$message.error(res.msg)
							}
						})
					}
				});
			},
			sendSms() {
				if(this.user.farenphone!==''){
					this.request.post("/sms/smsCode?phone=" + this.user.farenphone).then(res => {
						if (res.code === '200') {
							this.$message.success("发送成功!")
							let n = 60;
							let inval = setInterval(
							() => {
									if (n === 0) {
										this.param = '重新获取验证码'
										this.isDisabl=false
										localStorage.clear("smsCode")
										clearInterval(inval)
									} else {
										this.isDisabl=true
						 			    this.param = `${n--}秒后重发`
									}
								},1000)
							
							localStorage.setItem("smsCode", JSON.stringify(res.data.data))
						} else {
							this.$message.error("发送失败!")
						}
					})
				}else{
					
				}
			}
		}
	}
</script>

<style>
	.wrapper {
		height: 100vh;
		background-image: url(../images/bg.jpg);
		overflow: hidden;
	}
</style>
